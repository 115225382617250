import React, { Component } from "react";
import { BrowserRouter as Router } from 'react-router-dom';

import "./Navbar.css";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBAlert,
  MDBContainer,
} from "mdbreact";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoggedIn: false,
    };
  }




  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };


  render() {
    //Conditional Rendering Based on User Being Logged in
    let NavbarRight;
    let ActivationAlert;

      NavbarRight = (
        <MDBNavbarNav right>
          <MDBNavItem>
            <MDBNavLink to="/">Home</MDBNavLink>
          </MDBNavItem>
          <li className="nav-item">
                    <a className="nav-link" href="https://calendly.com/endtime-investments" data-offset="100">
                     Contact Us
                    </a>
                  </li>
    
          {/* <li className="nav-item">
                    <a className="nav-link" href="#about" data-offset="100">
                      About
                    </a>
                  </li> */}
        </MDBNavbarNav>
      );


    return (
        <Router>  
            <MDBContainer >

        <MDBNavbar style={{minHeight:"80px"}}  color="white" light expand="lg">
          <MDBNavbarBrand>
            <a href="/">
              <img
                src="./images/endtime-investment-logo.png"
                className="img-fluid"
                width="130px"
                alt="FaithConnect"
              />
            </a>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
         
            {NavbarRight}
          </MDBCollapse>
        </MDBNavbar>
        {ActivationAlert}
        </MDBContainer></Router>
    
    );
  }
}

export default Navbar;
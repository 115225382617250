import "./Home.css";
import React, { Component } from "react";
import Footer from "../../components/Footer";

import { MDBAvatar, MDBLightbox, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCard,MDBCardBody } from "mdbreact";
import Navbar from "../../components/Navbar"


class Home extends Component {
  state = {
    isOpen: false,
    heading: [
      {
        src:
          "https://faithconnect.s3.us-east-2.amazonaws.com/images/FaithConnect.png",
        description: (
          <a href="https://www.faithconnect.com">FaithConnect.com</a>
        ),
      },
      {
        src:
          "https://faithconnect.s3.us-east-2.amazonaws.com/images/usmIphone.png",
        description: (
          <a href="/">
            USM iOS App
          </a>
        ),
      },
      {
        src:
          "https://faithconnect.s3.us-east-2.amazonaws.com/images/usmAndroid.png",
        description: (
          <a href="/">
            USM Android App
          </a>
        ),
      },
      {
        src:
          "https://faithconnect.s3.us-east-2.amazonaws.com/merchant-pricing-solution-project.png",
        description: (
          <a href="https://www.merchantpricingsolutions.com">
            Merchant Pricing Solutions
          </a>
        ),
      },
    ],
  };

  render() {
    return (
      <div>
        {/* <Header /> */}
        <Navbar/>
        {/* <NavBar currentLocation={this.props.location.pathname} /> */}
        <div className="servicesPage">
          <div>
            <section className="view services-view">
              <video
                poster="https://mdbootstrap.com/img/Photos/Others/background.jpg"
                playsInline
                autoPlay
                muted
                loop
              >
                <source
                  src="https://faithconnect.s3.us-east-2.amazonaws.com/Videos/city.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="mask rgba-black-light">
                <div className="container h-100 d-flex justify-content-center align-items-center">
                  <div className="row">
                    <div className="col-md-12 white-text text-center smooth-scroll">
                      <div className="wow fadeInDown" data-wow-delay="0.2s">
                        <h3 className="display-4 font-weight-bold white-text mb-4">
                          We EMPOWER Businesses
                        </h3>
                        <hr className="hr-light my-4 clearfix d-none d-md-block" />
                        <h5
                          className="white-text description mx-3 mb-4 wow fadeIn"
                          data-wow-delay="0.3s"
                        >
                          Endtime Investments was created with the purpose of helping businesses and individuals in growing their businesses by implementing financial strategies and utilizing technology.

                        </h5>
                      </div>

                      

                      <a
                        href="#scheduling"
                        className="btn btn-white btn-rounded btn-lg orange-text font-weight-bold ml-lg-0 wow fadeIn"
                        data-wow-delay="0.3s"
                        data-toggle="tooltip"
                        title="We will design it, and build it!"
                      >
                        <i
                          className="fas fa-play-circle orange-text mr-2 material-tooltip-main"
                          aria-hidden="true"
                        />{" "}
                        Let us Build It
                      </a>
                      <a
                        href="https://calendly.com/endtime-investments"
                        className="btn blue-gradient btn-rounded btn-lg font-weight-bold wow fadeIn material-tooltip-main"
                        data-wow-delay="0.3s"
                        data-toggle="tooltip"
                        title="Build it yourself with our easy site builder!"
                      >
                        Schedule a Call!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <a name="awesome-features"></a>
          </div>

          <div className="container">
            {/* Section: Features v.4 */}

            <section id="features" className="text-center my-5">
              {/* Section heading */}
              <h2
                className="title font-weight-bold my-5 wow fadeIn"
                data-wow-delay="0.2s"
              >
                <strong>Awesome features</strong>
              </h2>
              {/* Section description */}
              <p
                className="grey-text w-responsive mx-auto mb-5 wow fadeIn"
                data-wow-delay="0.2s"
              >
                We enjoy building new websites and love helping businesses grow.
                Whether you need to build an informational website, an
                e-commerce store, or a fully custom solution such as a video
                streaming service, or business directory, we would love to help
                you build it.
              </p>
              {/* Grid row */}
              <div className="row wow fadeIn" data-wow-delay="0.2s">
                {/* Grid column */}
                <div className="col-lg-4 text-center">
                  <div className="icon-area">
                    <div className="circle-icon">
                      <i className="fas fa-cogs blue-text" />
                    </div>
                    <br />
                    <h5 className="dark-grey-text font-weight-bold mt-2">
                      Custom Websites
                    </h5>
                    <div className="mt-1">
                      <p className="mx-3 grey-text">
                        Have a creative idea and need a website? Are you
                        creating the next big thing? We can help!
                      </p>
                    </div>
                  </div>
                </div>
                {/* Grid column */}
                {/* Grid column */}
                <div className="col-lg-4 text-center">
                  <div className="icon-area">
                    <div className="circle-icon">
                      <i className="fas fa-store blue-text" />
                    </div>
                    <br />
                    <h5 className="dark-grey-text font-weight-bold mt-2">
                      E-Commerce Websites
                    </h5>
                    <div className="mt-1">
                      <p className="mx-3 grey-text">
                        Want to sell products online and don't know where to
                        start? We can help!
                      </p>
                    </div>
                  </div>
                </div>
                {/* Grid column */}
                {/* Grid column */}
                <div className="col-lg-4 text-center mb-4">
                  <div className="icon-area">
                    <div className="circle-icon">
                      <i className="fas fa-users blue-text" />
                    </div>
                    <br />
                    <h5 className="dark-grey-text font-weight-bold mt-2">
                      Directory Websites
                    </h5>
                    <div className="mt-1">
                      <p className="mx-3 grey-text">
                        We offer the technology that powers <a href="https://www.faithconnect.com">FaithConnect's</a>
                         {" "}Business Directory as a software service!
                      </p>
                    </div>
                  </div>
                </div>

                {/* Grid column */}
              </div>
              {/* Grid row */}
            </section>
            {/* Section: Features v.4 */}
            <hr />
            {/* Section: About 1 */}
            <section
              id="about"
              className="mt-5 mb-5 py-3 wow fadeIn"
              data-wow-delay="0.2s"
            >
              {/* Grid row */}
              <div className="row pt-2 mt-lg-5">
                {/* Grid column */}
                <div
                  className="col-lg-6 col-md-12 mb-3 wow fadeIn"
                  data-wow-delay="0.4s"
                >
                  {/* Image */}
                  <img
                    src="https://faithconnect.s3.us-east-2.amazonaws.com/images/macbookFaithConnectWebsite.png"
                    className="img-fluid rounded"
                    alt="My photo"
                  />
                </div>
                {/* Grid column */}
                {/* Grid column */}
                <div
                  className="col-lg-6 ml-auto col-md-12 wow fadeIn"
                  data-wow-delay="0.4s"
                >
                  {/* Secion heading */}
                  <h2
                    className="mb-5 title font-weight-bold wow fadeIn"
                    data-wow-delay="0.2s"
                  >
                    <strong>Build your brand with us</strong>
                  </h2>
                  {/* Description */}
                  <p align="justify" className="grey-text">
                    It's hard to do things on your own, we can be the partner
                    you need to build your brand or take it to the next level.
                    From solopreneurs to large enterprises, we can provide
                    solutions to all size budgets (Try us!!) we provide
                    customized solutions for your business. Trust that our
                    professional designs fit just for you.
                  </p>
                  <p className="grey-text">
                    <i
                      className="fas fa-long-arrow-alt-right orange-text mr-2"
                      aria-hidden="true"
                    />
                    Designs that fit your business
                  </p>
                  <p className="grey-text">
                    <i
                      className="fas fa-long-arrow-alt-right orange-text mr-2"
                      aria-hidden="true"
                    />
                    Fit for every budget
                  </p>
                  <p className="grey-text">
                    <i
                      className="fas fa-long-arrow-alt-right orange-text mr-2"
                      aria-hidden="true"
                    />
                    From simple websites to complex web applications
                  </p>
                </div>
                {/* Grid column */}
              </div>
              {/* Grid row */}
            </section>
            {/* Section: About 1 */}

            {/* Section: About 2 */}
            {/* Section: About 2 */}

            {/* Section: Pricing v.5 */}
          </div>

          <div>
            {/* Streak */}
            <div
              className=""
              style={{
                backgroundImage:
                  'url("https://faithconnect.s3.us-east-2.amazonaws.com/images/webservices-features-background.jpg")',
              }}
            >
              <div className="flex-center view services-view-features rgba-indigo-light">
                <div className="container py-3">
                  {/* Section: Features v.4 */}
                  <section className="wow fadeIn" data-wow-delay="0.2s">
                    {/* Section heading */}
                    <h2
                      className="py-3 my-3 pb-5 white-text font-weight-bold text-center wow fadeIn"
                      data-wow-delay="0.2s"
                    >
                      {/* <img
                        src="./images/endtime-investment-logo.png"
                        className="img-fluid mx-auto pb-5"
                        style={{ width: "300px" }}
                        alt="FaithConnect"
                      /> */}
                      Why choose Endtime Investments!
                    </h2>

                    {/* Grid row */}
                    <div className="row mb-3">
                      {/* Grid column */}
                      <div className="col-md-12 col-lg-6">
                        {/* Grid row */}
                        <div className="row mb-5">
                          <div className="col-2">
                            <a
                              type="button"
                              className="btn-floating white btn-lg my-0"
                            >
                              <i
                                className="fas fa-tablet-alt blue-text"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <div className="col-9 pl-5">
                            <h4 className="text-left font-weight-bold white-text">
                              Fully Responsive
                            </h4>
                            <p className=" text-left white-text">
                              We make sure you websites looks good in all
                              devices from smartphones to large screens.
                            </p>
                          </div>
                        </div>
                        {/* Grid row */}
                        {/* Grid row */}
                        <div className="row mb-5">
                          <div className="col-2">
                            <a
                              type="button"
                              className="btn-floating white btn-lg my-0"
                            >
                              <i
                                className="fas fa-level-up-alt blue-text"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <div className=" col-9 pl-5">
                            <h4 className="text-left font-weight-bold white-text">
                              Latest Technologies
                            </h4>
                            <p className=" text-left white-text">
                              Our websites are build using the latest
                              technologies to guarantee best performance.
                            </p>
                          </div>
                        </div>
                        {/* Grid row */}
                        {/* Grid row */}
                        <div className="row mb-5">
                          <div className="col-2">
                            <a
                              type="button"
                              className="btn-floating white btn-lg my-0"
                            >
                              <i
                                className="fas fa-envelope blue-text"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <div className="col-9 pl-5">
                            <h4 className="text-left font-weight-bold white-text">
                              Technical support
                            </h4>
                            <p className="text-left white-text">
                              We are here for you whenever you need us, we are
                              available 24/7 via email.
                            </p>
                          </div>
                        </div>
                        {/* Grid row */}
                      </div>
                      {/* Grid column */}
                      {/* Grid column */}
                      <div className="col-md-12 col-lg-6">
                        {/* Grid row */}
                        <div className="row mb-5">
                          <div className="col-2">
                            <a
                              type="button"
                              className="btn-floating white btn-lg my-0"
                            >
                              <i
                                className="far fa-object-group blue-text"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <div className="col-9 pl-5">
                            <h4 className="text-left font-weight-bold white-text">
                              Custom Designs
                            </h4>
                            <p className="text-left white-text">
                              Get the look and feel of a professionaly built
                              website customized just for you.
                            </p>
                          </div>
                        </div>
                        {/* Grid row */}
                        {/* Grid row */}
                        <div className="row mb-5">
                          <div className="col-2">
                            <a
                              type="button"
                              className="btn-floating white btn-lg my-0"
                            >
                              <i
                                className="fas fa-rocket blue-text"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <div className="col-9 pl-5">
                            <h4 className="text-left font-weight-bold white-text">
                              We Grow with your business
                            </h4>
                            <p className="text-left white-text">
                              From simple webpages to full web applications, we
                              are here to grow with your business goals.
                            </p>
                          </div>
                        </div>
                        {/* Grid row */}
                        {/* Grid row */}
                        <div className="row mb-5">
                          <div className="col-2">
                            <a
                              type="button"
                              className="btn-floating white btn-lg my-0"
                            >
                              <i
                                className="fa fa-dollar-sign blue-text"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <div className="col-9 pl-5">
                            <h4 className="text-left font-weight-bold white-text">
                              Affordable
                            </h4>
                            <p className="text-left white-text">
                              Solutions for all size businesses and budgets. If
                              you can dream it, we can build it!
                            </p>
                          </div>
                        </div>
                        {/* Grid row */}
                      </div>
                      {/* Grid column */}
                    </div>
                    {/* Grid row */}
                  </section>
                  {/* Section: Features v.4 */}
                </div>
              </div>
            </div>
          </div>

        


          <a name="scheduling"></a>
          <div className="container">
            <section className="text-center my-5">
              {/* Section heading */}
              <h2
                className="title font-weight-bold my-5 wow fadeIn"
                data-wow-delay="0.2s"
              >
                <strong>Let Us Build it For You (Custom)</strong>
              </h2>
              {/* Section description */}
              <p className="grey-text w-responsive mx-auto mb-5">
                We offer many different pricing packages. Let us know what you
                need, and we can put something together that will fit your
                budget!
              </p>
              {/* Grid row */}
              <div className="row">
                {/* Grid column */}
                <div className="col-lg-4 col-md-12 mb-5">
                  {/* Card */}
                  <div className="pricing-card card">
                    {/* Content */}
                    <div className="card-body">
                      <h5 className="font-weight-bold mt-3">
                        Informational Website
                      </h5>
                      {/* Price */}
                      <div className="price pt-0">
                        <h2 className="number orange-text mb-0">99</h2>
                      </div>
                      {/* Price */}
                      <ul className="list-unstyled striped title darker-striped">
                        <li>
                          <p>
                            <strong>Free</strong> Set-Up
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>5</strong> Site Pages
                          </p>
                        </li>
                        <li>
                          <p>Professional Look and Feel</p>
                        </li>
                        <li>
                          <p>Basic Social Media Integration</p>
                        </li>

                        <li>
                          <p>
                            10 <strong>Products</strong> for Sale
                          </p>
                        </li>
                      </ul>
                      <a
                        href="https://calendly.com/endtime-investments"
                        className="btn pink-gradient btn-rounded mb-4 white-text"
                      >
                        {" "}
                        Schedule Call!
                      </a>
                    </div>
                  </div>
                  {/* Card */}
                </div>
                {/* Grid column */}
                {/* Grid column */}
                <div className="col-lg-4 col-md-12 mb-5">
                  {/* Card */}
                  <div
                    className="card card-image"
                    style={{
                      backgroundImage:
                        'url("https://mdbootstrap.com/img/Photos/Others/pricing-table%20(8).jpg")',
                    }}
                  >
                    {/* Content */}
                    <div className="text-white text-center pricing-card d-flex align-items-center rgba-indigo-strong py-3 px-3 rounded">
                      {/* Content */}
                      <div className="card-body">
                        <h5 className="font-weight-bold mt-2">
                          eCommerce Store
                        </h5>
                        {/* Price */}
                        <div className="price pt-0">
                          <h2 className="number mb-0">199</h2>
                        </div>
                        {/* Price */}
                        <ul className="list-unstyled striped">
                          <li>
                            <p>
                              <strong>Free</strong> Set-up
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Unlimited</strong> Site Pages
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Unlimited</strong> Products
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Shopify</strong> Management
                            </p>
                          </li>
                          <li>
                            <p>Social Media Integration</p>
                          </li>
                        </ul>
                        <a
                          href="https://calendly.com/endtime-investments"
                          className="btn btn-rounded btn-outline-white white-text"
                        >
                          {" "}
                          Schedule Call!
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Card */}
                </div>
                {/* Grid column */}
                {/* Grid column */}
                <div className="col-lg-4 col-md-12 mb-5">
                  {/* Card */}
                  <div className="pricing-card card">
                    {/* Content */}
                    <div className="card-body">
                      <h5 className="font-weight-bold mt-3">
                        Custom Web Application
                      </h5>
                      {/* Price */}
                      <div className="price pt-0">
                        <h2 className="number orange-text mb-0">499</h2>
                      </div>
                      {/* Price */}
                      <ul className="list-unstyled striped title darker-striped">
                        <li>
                          <p>
                            Fully <strong>Custom</strong> Application
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>50+</strong> Business Types
                          </p>
                        </li>
                        <li>
                          <p>Back-End Services</p>
                        </li>
                        <li>
                          <p>Cloud Services</p>
                        </li>
                        <li>
                          <p>Engineering Support</p>
                        </li>
                      </ul>
                      <a
                        href="https://calendly.com/endtime-investments"
                        className="btn pink-gradient btn-rounded mb-4 white-text"
                      >
                        Schedule Call!
                      </a>
                    </div>
                  </div>
                  {/* Card */}
                </div>
                {/* Grid column */}
              </div>
              {/* Grid row */}
              <h5>
                Have questions? Can't find what you are looking for?{" "}
                <strong>
                  <a href="https://calendly.com/endtime-investments">Contact Us!</a>
                </strong>
              </h5>
            </section>
            <hr></hr>
            {/* Section: Pricing v.5 */}
          </div>

          <div className="container">
            {/* Section: About 2 */}
            <section
              id="about"
              className="mt-5  py-3 wow fadeIn"
              data-wow-delay="0.2s"
            >
              <a id="mobileDevelopment"></a>
              {/* Grid row */}
              <div className="row pt-5 wow fadeIn" data-wow-delay="0.4s">
                {/* Grid column */}
                <div className="col-md-6 col-lg-8  text-left ">
                  {/* Secion heading */}

                  <h2
                    className="mb-5 title font-weight-bold wow fadeIn text-center"
                    data-wow-delay="0.2s"
                  >
                    <strong>Mobile Application Development</strong>
                    <div className="mt-3">
                      <a
                        href="https://calendly.com/endtime-investments"
                        className="btn pink-gradient btn-rounded  white-text"
                      >
                        {" "}
                        Schedule Call!
                      </a>
                    </div>
                  </h2>

                  {/* Description */}
                  <p align="justify" className="grey-text">
                    Have an idea for a mobile application. Lets build it!
                  </p>
                  <p align="justify" className="grey-text">
                    We build beautiful feature-rich, scalable mobile apps for
                    startups and well-established businesses. We, at
                    Endtime Investments are passionate to develop solutions that will
                    help you expand your marketing reach!
                  </p>
                  <p align="justify" className="grey-text">
                    Mobile Development doesn't have to be difficult :) Let's
                    have a conversation, and find out how we can release your
                    first mobile application.
                  </p>
                  <div className="text-center">
                    <div class=" smooth-scroll mx-4 grey-text">
                      <h5 class="h4-responsive wow fadeIn mb-5 mt-5">
                        <i
                          class=" grey-text fas fa-quote-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Design is not just what it looks like and feels like.
                        Design is how it works.{" "}
                        <i class="fas fa-quote-right" aria-hidden="true"></i>
                      </h5>
                      <h5
                        class="text-center font-italic wow fadeIn"
                        data-wow-delay="0.2s"
                      >
                        ~ Steve Jobs
                      </h5>
                    </div>
                  </div>
                </div>
                {/* Grid column */}
                {/* Grid column */}
                <div className="col-md-6 col-lg-3 mt-md-4 mt-lg-0 ml-auto mb-5 text-center">
                  {/* Image */}
                  <img
                    src="https://faithconnect.s3.us-east-2.amazonaws.com/images/iphone-service-page-screenshot-version4.png"
                    className="img-fluid rounded z-depth-0"
                    alt="My photo"
                  />
                </div>
                {/* Grid column */}
              </div>
              {/* Grid row */}
            </section>
            {/* Section: About 2 */}
            <hr />
          </div>
          
        </div>
        
        <div className="container text-center">
            <section id="works" className="section mb-5">
              <h2
                className="text-center text-uppercase my-5 pt-5 wow fadeIn"
                data-wow-delay="0.2s"
              >
                Our <strong> Development projects</strong>
              </h2>

              <p className="text-center w-responsive mx-auto wow fadeIn my-5">
                From FrontEnd to Backend to UI/UX Design, we enjoy building
                different applications that will help your business soar.
              </p>

              <div className="row wow fadeIn" data-wow-debilay="0.4s">
                <div className="col-md-12 ">
                  <div id="mdb-lightbox-ui"></div>
                  <MDBContainer>
                    <MDBLightbox md="4" images={this.state.heading} noMargins />
                  </MDBContainer>{" "}
                </div>
              </div>
            </section>
            <MDBRow center>
        </MDBRow>

          </div>
        <Footer />
      </div>
      
    );
  }
}

export default Home;
import React, { Component } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBFooter,
  MDBBtn,
  MDBIcon,
} from "mdbreact";

class FooterPage extends Component {
  render() {
    const iosImgStyle = {
      width: "50%",
      padding: "10px",
    };

    const androidImgStyle = {
      width: "50%",
      padding: "5px",
    };

    return (
      <MDBFooter
        color="unique-color-dark"
        className="page-footer font-small pt-0"
      >
        <div style={{ backgroundColor: "#3f51b5" }}>
          <MDBContainer fluid className="text-center text-md-left">
            <MDBRow className="py-4 d-flex align-items-center">
              <MDBCol
                md="6"
                lg="5"
                className="text-center text-md-left mb-4 mb-md-0"
              >
                <h6 className="mb-0 white-text">
                  Get connected with us on social networks!
                </h6>
              </MDBCol>
              {/* <MDBCol md="6" lg="7" className="text-center text-md-right">
                <a
                  href="https://www.facebook.com/faithconnect"
                  className="fb-ic ml-0 p-1"
                >
                  <i className="fab fa-facebook-f white-text mr-lg-4"> </i>
                </a>
                <a
                  href="https://twitter.com/faithconnectus"
                  className="tw-ic p-1"
                >
                  <i className="fab fa-twitter white-text mr-lg-4"> </i>
                </a>

                <a
                  href="https://www.linkedin.com/company/faithconnect/"
                  className="li-ic p-1"
                >
                  <i className="fab fa-linkedin-in white-text mr-lg-4"> </i>
                </a>
                <a
                  href="https://www.instagram.com/faithconnect_us/"
                  className="ins-ic p-1"
                >
                  <i className="fab fa-instagram white-text mr-lg-4"> </i>
                </a>
              </MDBCol> */}
            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer className="mt-5 mb-4 text-md-left">
          <MDBRow center className="mt-3">
          <MDBCol md="6" lg="6" xl="6" className="mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <strong>Our Services</strong>
              </h6>
              <hr
                className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: "60px" }}
              />
              <p>
                <a href="/">Finance and Accounting</a>
              </p>
              <p>
                <a href="/">Web Development</a>
              </p>
              <p>
                <a href="/">Mobile Development</a>
              </p>
              <p>
                <a href="/">eCommerce</a>
              </p>
            </MDBCol>
            <MDBCol md="6" lg="6" xl="6" className="mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <strong>Endtime Investments, LLC</strong>
              </h6>
              <hr
                className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: "60px" }}
              />
              <p>
                Welcome to Endtime Investments, LLC, we help businesses and individuals in growing their businesses by implementing financial strategies and utilizing technology.
              </p>
            </MDBCol>
          
           
            
          </MDBRow>
   
        </MDBContainer>
        <div className="footer-copyright text-center py-3">
          <MDBContainer fluid>
            &copy; {new Date().getFullYear()} Copyright:{" "}
            <a href="https://www.endtimeinvestments.com"> Endtime Investments, LLC </a>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}

export default FooterPage;